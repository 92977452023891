<script setup>
  import { computed, ref } from 'vue'
  import { useBackend } from '@/composables/Backend';
  import { useRoute } from 'vue-router';

  const backend = useBackend();
  const route = useRoute();

  // form values  
  const email = ref('');
  const password = ref('');
  const confirmPassword = ref('');

  const token = route.query.token; // TODO: validate token or leave it to backend?  
  const isOnFirstStep = computed(() => { return !token; });
  const isWorking = ref(false);
  const isSubmitted = ref(false);
  const error = ref(false);
  const validationError = ref(false);

  const submitButtonText = computed(() => {
    if (isWorking.value) {
      return 'Please wait...';
    } 
    else if (isOnFirstStep.value) {
      return 'Change password →';
    }
    else {
      return 'Send reset link ->';
    }
  });

  const submitButtonCss = computed(() => {
    var css = 'button primary__btn w-button';
    if (isWorking.value) {
      css + ' is--waiting ';
    }    
    return css;
  });

  // function submitForm() {
  //   isWorking.value = true;
  //   validationError.value = false;

  //   // validate password
  //   if (password.value != confirmPassword.value) {
  //     validationError.value = "Passwords don't match!";
  //   }
  //   else if (password.value.length <= 8) {
  //     validationError.value = "Sorry, password must be longer than 8 characters.";
  //   }
  //   else {
  //     // make call to backend
  //     if (isOnFirstStep.value) {
  //       backend.sendPasswordReset(email.value)
  //         .then(result => {
  //           handleResult(result);
  //         });
  //     }
  //     else {
  //       backend.resetPassword(password.value, confirmPassword.value, token)
  //         .then(result => {
  //           handleResult(result);
  //         });
  //     }
  //   }                
  // }

  function submitForm() {
    isWorking.value = true;
    validationError.value = false;

    if (!isOnFirstStep.value) {
      // Validate passwords only on the second step
      if (password.value !== confirmPassword.value) {
        validationError.value = "Passwords don't match!";
        return;
      } else if (password.value.length <= 8) {
        validationError.value = "Sorry, password must be longer than 8 characters.";
        return;
      }
    }

    // Make call to backend
    if (isOnFirstStep.value) {
      backend.sendPasswordReset(email.value)
        .then(result => {
          handleResult(result);
        });
    } else {
      backend.resetPassword(password.value, confirmPassword.value, token)
        .then(result => {
          handleResult(result);
        });
    }
  }


  function handleResult(result) {
    if (result == false || result.error) {
      // handle error
      error.value = result.error;
      if (!error.value) {
        error.value = 'An error occurred communicating with the API.';
      }
      error.value = `Oops! An error occurred during the password reset process. ${error.value}`;
      
      if (result.message) {
        error.value = `${error.value}. ${result.message}`;
      }
    }
    isSubmitted.value = true;
    isWorking.value = false;
  }
</script>

<template>
  <div class="section__container section__flex alt">
    <div class="section__grid-col grid-col--50">
      <div class="div-block-4 load-1">
        <template v-if="isOnFirstStep">
          <h2>Forgot Password</h2>
          <div class="dashed-divider"></div>
          <p class="no--margin-b">
            Did you forget your password?
          </p>
          <h3 class="regular no--margin-b">
            - Enter your email and we'll send you a password reset link<br>
            - You will only receive an email if we can find your email address in our system<br>
            - Check your junk mail folder if you haven't received an email within a few minutes
          </h3>
        </template>
        <template v-else>
          <h2>Reset Password</h2>
          <div class="dashed-divider"></div>
          <p class="no--margin-b">
            Enter a new password.
          </p>
          <h3 class="regular no--margin-b">
            - Password Requirements: Please use at least 8 characters, along with a special character.
          </h3>
        </template>
      </div>
    </div>
    <div class="modal__body modal__body--fill">
      <div class="w-form">
        <div v-if="validationError" class="alert alert-danger">{{ validationError }}</div>
        <form v-if="!isSubmitted" id="wf-form-passwordReset" name="wf-form-passwordReset" method="post" aria-label="Reset password form">
          <template v-if="isOnFirstStep">
            <div class="text-input">
              <input v-model="email" id="email" name="email" type="email" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="" style="width: 640px;">
              <div class="label w-embed"><label class="text-input__label" for="email">Email</label></div>
            </div> 
          </template>
          <template v-else>
            <div class="text-input">
              <input v-model="password" id="password" name="password" type="password" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="">
              <div class="label w-embed"><label class="text-input__label" for="password">Password</label></div>
            </div>
            <div class="text-input">
              <input v-model="confirmPassword" id="confirmPassword" name="confirmPassword" type="password" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="">
              <div class="label w-embed"><label class="text-input__label" for="confirmPassword">Confirm Password</label></div>
            </div> 
          </template>                   
          <div class="form__footer">
            <a @click="submitForm" href="#" :class="submitButtonCss">{{ submitButtonText }}</a>
          </div>
        </form>
        <div v-if="isSubmitted && !error" class="form__success" tabindex="-1" role="region" aria-label="Reset password form success">
          <div>
            <div class="form__success--row">
              <img src="https://cdn.prod.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19e3_icon-success.svg" alt="Success"/>
              <h5 v-if="isOnFirstStep" class="form__success-message large">Check your inbox for our email with the password reset link!</h5>
              <h5 v-else class="form__success-message large">Your password has been reset</h5>
            </div>
            <a href="/" class="trigger--pageout button button--mt w-button">Keep Shopping</a>
          </div>
        </div>
        <div v-if="error" class="w-form-fail" tabindex="-1" role="region" aria-label="Reset password form failure">
          <div>{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>